import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { UserDetails } from "../models/user.model";
import { ApiResponse } from "../models/plan.model";
import { environment } from "../../environments/environments";

@Injectable({
  providedIn: "root",
})
export class UserService {
  API_URL = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getUser(): Observable<any> {
    return this.http.get<any>(`${this.API_URL}user/get_user`);
  }

  getSubscribedPlanByUser(id: string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.API_URL + "user/get_subscribed_plan_by_user/" + id
    );
  }

  ganerateImage(data: any): Observable<any> {
    return this.http.post<any>(`${this.API_URL}DALL-E-3/gpt-4`, data);
  }
}

import { Injectable } from "@angular/core";
import { environment } from "../../environments/environments";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ResponseData } from "../models/chatHistory.model";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  BASE_URL = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getChatHistory(): Observable<ResponseData> {
    return this.http.get<ResponseData>(`${this.BASE_URL}user/chat_history`);
  }
}

import { CommonModule } from "@angular/common";
import { Component, ElementRef, HostListener, Renderer2 } from "@angular/core";
import { NavigationEnd, Router, RouterModule } from "@angular/router";
import { filter } from "rxjs";

@Component({
  selector: "app-footer",
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: "./footer.component.html",
  styleUrl: "./footer.component.scss",
})
export class FooterComponent {
  email: string = "info@jewelkitpro.com";
  url: string = "";

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.url = event.url;
      });
      this.url = this.router.url;
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    const animateElements =
      this.el.nativeElement.querySelectorAll(".animate__animated");

    if (scrollPosition > 10) {
      animateElements.forEach((element: HTMLElement) => {
        this.renderer.addClass(element, "animate__fadeInUp");
      });
    } else {
      animateElements.forEach((element: HTMLElement) => {
        this.renderer.removeClass(element, "animate__fadeInDown");
      });
    }
  }
}

<div class="col-12 result">
  <div class="card p-0">
    <div class="card-header p-4">
      <h2>Your Designs</h2>
    </div>
    <div class="card-body">
      <div class="row">
        @if (chatHistory?.length === 0) {
        <div class="d-flex justify-content-center align-items-center">
          History Not Available
        </div>
        } @for(item of chatHistory; track item){

        <div
          class="col-md-4 col-12 p-4 res-container d-flex justify-content-center"
        >
          <div class="skeleton-loader" *ngIf="isImageLoad"></div>

          <img
            *ngIf="item?.prompt_image"
            [src]="item?.prompt_image"
            class="img-res"
            (load)="onLoad()"
            alt=""
            loading="lazy"
          />
          <div class="download_img" *ngIf="!isImageLoad && item?.prompt_image">
            <span class="download_btn" (click)="download(item?.prompt_image)">
              <img
                src="../../../assets/images/download1.png"
                width="30"
                height="30"
              />
            </span>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</div>

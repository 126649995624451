import { CommonModule } from "@angular/common";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  NgxStripeModule,
  StripeCardComponent,
  StripeFactoryService,
  StripeInstance,
} from "ngx-stripe";
import { Subscription, from, switchMap } from "rxjs";
import { PalnsService } from "../../services/palns.service";
import { Plan } from "../../models/plan.model";
import { UserService } from "../../services/user.service";
import { CONSTANTS } from "../../constants/constant";
import { environment } from "../../../environments/environments";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-our-pricing",
  standalone: true,
  imports: [CommonModule, StripeCardComponent, NgxStripeModule, FormsModule],
  templateUrl: "./our-pricing.component.html",
  styleUrl: "./our-pricing.component.scss",
})
export class OurPricingComponent {
  public stripe!: StripeInstance;
  public stripeAmount!: number;
  public stripePublicKey = CONSTANTS.STRIPE_PUBLIC_KEY;
  planId: string = "";
  userId: any;
  model: any = {};
  private subscriptions: Subscription;
  plans: Plan[] = [];
  currentPlan: boolean = false;
  isLoading: boolean = false;
  clickedPlanId: string | null = null;
  isEmpty: any;
  constructor(
    private http: HttpClient,
    private stripeFactory: StripeFactoryService,
    private planService: PalnsService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router
  ) {
    this.subscriptions = new Subscription();
  }

  ngOnInit(): void {
    const authToken = localStorage.getItem("authToken");
    this.userId = localStorage.getItem("userId");
    if (!authToken && !this.userId) {
      this.router.navigate(["/login"]);
    }
    this.stripe = this.stripeFactory.create(this.stripePublicKey);
    this.loadPlans();
    this.getUsers();
  }

  getUsers() {
    this.userService.getUser().subscribe((res: any) => {
      this.model = res.ResponseData;
      // this.userId = this.model._id;
      this.planId = res.ResponseData.planId;
    });
  }

  loadPlans() {
    this.planService.getAllPlans().subscribe((res) => {
      this.plans = res.ResponseData;
      // console.log(this.userId !== '' && this.planId != null);
      // if (this.userId !== "" && this.planId !== "") {
      //   this.userService
      //     .getSubscribedPlanByUser(this.userId)
      //     .subscribe((response: any) => {
      //       this.plans.map((item: any) => {
      //         if (item._id === response.ResponseData._id) {
      //           item.currentPlan = true;
      //         }
      //       });
      //     });
      // }
    });
  }

  checkout(plan: any) {
    this.isLoading = true;
    localStorage.setItem("planId", plan._id);

    const payload = {
      amount: plan.planPrice,
      currency: plan.planCurrency,
      name: plan.planName,
      planId: plan._id,
      userId: this.userId,
    };
    // console.log(payload);
    // return;

    this.isLoading = true;
    this.clickedPlanId = plan._id;
    // return;
    const checkout = this.http
      .post(
        `${environment.apiUrl}payment/checkout`,
        payload,

        { observe: "response" }
      )
      .pipe(
        switchMap((response: HttpResponse<Object>) => {
          const session: IStripeSession | any = response.body as IStripeSession;
          return from(
            this.stripe.redirectToCheckout({
              sessionId: session.ResponseData.id,
            })
          );
        })
      )
      .subscribe();

    this.subscriptions.add(checkout);
    // if (userId) {
    //   localStorage.setItem("planId", plan._id);
    //   // this.userId =
    //   //   localStorage.getItem("userId") !== null
    //   //     ? localStorage.getItem("userId")
    //   //     : this.userId;
    //   const payload = {
    //     amount: plan.planPrice,
    //     currency: plan.planCurrency,
    //     name: plan.planName,
    //     planId: plan._id,
    //     userId: userId,
    //   };
    //   // console.log(payload);
    //   // return;

    //   this.isLoading = true;
    //   this.clickedPlanId = plan._id;
    //   // return;
    //   const checkout = this.http
    //     .post(
    //       `${environment.apiUrl}payment/checkout`,
    //       payload,

    //       { observe: "response" }
    //     )
    //     .pipe(
    //       switchMap((response: HttpResponse<Object>) => {
    //         const session: IStripeSession | any =
    //           response.body as IStripeSession;
    //         return from(
    //           this.stripe.redirectToCheckout({
    //             sessionId: session.ResponseData.id,
    //           })
    //         );
    //       })
    //     )
    //     .subscribe
    //     // {
    //     // next: async (result: any) => {
    //     //   // If `redirectToCheckout` fails due to a browser or network error
    //     //   if (result.error) {
    //     //     console.log(result.error);
    //     //   } else {
    //     //     // Assume success and send confirmation to backend
    //     //     this.isLoading = false;
    //     //     await this.http
    //     //       .post(`${environment.apiUrl}payment/confirm`, {
    //     //         sessionId: result.sessionId,
    //     //         userId: this.userId,
    //     //         planId: plan._id,
    //     //       })
    //     //       .toPromise();
    //     //   }
    //     // },
    //     // error: (error) => {
    //     //   console.error("Checkout process failed", error);
    //     // },
    //     // }
    //     ();

    //   this.subscriptions.add(checkout);
    // } else {
    //   this.router.navigate(["/login"]);
    // }
  }
}

interface IStripeSession {
  id: string;
}

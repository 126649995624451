import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { ApiResponse, Plan } from "../../models/plan.model";
import { PalnsService } from "../../services/palns.service";

@Component({
  selector: "app-pricing",
  standalone: true,
  imports: [RouterModule, SlickCarouselModule, CommonModule],
  templateUrl: "./pricing.component.html",
  styleUrl: "./pricing.component.scss",
})
export class PricingComponent {
  plans: Plan[] = [];
  model: any = {};
  slides = [
    {
      img: "../../../assets/images/banner7.jpg",
      title: "Elegant Diamond Collection",
      description:
        "Discover the timeless elegance of our diamond collection. Each piece is crafted to perfection, ensuring brilliance and unmatched quality.",
      cta: "Shop Now",
      link: "/shop/diamond-collection",
    },
    {
      img: "../../../assets/images/banner7.jpg",
      title: "Perfect Engagement Rings",
      description:
        "Celebrate your special moment with our exquisite selection of engagement rings, designed to symbolize your everlasting love.",
      cta: "Explore Collection",
      link: "/shop/engagement-rings",
    },
    {
      img: "../../../assets/images/banner7.jpg",
      title: "Custom Jewelry Designs",
      description:
        "Create your dream piece with our custom jewelry design service. Our experts will bring your vision to life with precision and artistry.",
      cta: "Start Designing",
      link: "/custom-design",
    },
    {
      img: "../../../assets/images/banner7.jpg",
      title: "Anniversary Gift Ideas",
      description:
        "Find the perfect gift for your loved one with our curated selection of anniversary jewelry. Celebrate your milestones with elegance.",
      cta: "Browse Gifts",
      link: "/shop/anniversary-gifts",
    },
    {
      img: "../../../assets/images/banner7.jpg",
      title: "Elegant Diamond Collection",
      description:
        "Discover the timeless elegance of our diamond collection. Each piece is crafted to perfection, ensuring brilliance and unmatched quality.",
      cta: "Shop Now",
      link: "/shop/diamond-collection",
    },
    {
      img: "../../../assets/images/banner7.jpg",
      title: "Perfect Engagement Rings",
      description:
        "Celebrate your special moment with our exquisite selection of engagement rings, designed to symbolize your everlasting love.",
      cta: "Explore Collection",
      link: "/shop/engagement-rings",
    },
    {
      img: "../../../assets/images/banner7.jpg",
      title: "Custom Jewelry Designs",
      description:
        "Create your dream piece with our custom jewelry design service. Our experts will bring your vision to life with precision and artistry.",
      cta: "Start Designing",
      link: "/custom-design",
    },
    {
      img: "../../../assets/images/banner7.jpg",
      title: "Anniversary Gift Ideas",
      description:
        "Find the perfect gift for your loved one with our curated selection of anniversary jewelry. Celebrate your milestones with elegance.",
      cta: "Browse Gifts",
      link: "/shop/anniversary-gifts",
    },
  ];
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  url: string = "";
  constructor(private router: Router, private _planService: PalnsService) {}
  ngOnInit(): void {
    this.url = this.router.url;
    this.loadData();
  }

  loadData() {
    this._planService.getAllPlans().subscribe((res: ApiResponse) => {
      this.plans = res.ResponseData;
    });
  }
}

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { Subscription, switchMap } from 'rxjs';
import { StripeInstance } from 'ngx-stripe';
import { environment } from '../../environments/environments';

interface IStripeSession {
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class StripePaymentService {
  // API_URL = environment.apiUrl;
  // public stripe!: StripeInstance;
  // public stripeAmount!: number;
  // public stripePublicKey =
  //   'pk_test_51PNr0r02646MFSZc8IMGf4usNW9qmtijYCqd6LwUkoR086CnBNy9daUfcZyfQwu3rBmd9QzLrSf0WR7bKup5yDwn005H2ElCrz';

  // private subscriptions: Subscription;
  constructor(private http: HttpClient) {}

  // checkout(data: any) {
  //   return this.http
  //     .post(environment.apiUrl + 'payment/checkout', data, {
  //       observe: 'response',
  //     })
  //     .pipe(
  //       switchMap((response: HttpResponse<Object>) => {
  //         const session: IStripeSession = response.body as IStripeSession;
  //         return this.stripe.redirectToCheckout({ sessionId: session.id });
  //       })
  //     );
  // }

  confirmPayment(data: any) {
    return this.http.post(environment.apiUrl + 'payment/confirm', data);
  }
}

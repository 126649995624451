import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { Observable } from "rxjs";
import { LoaderService } from "./services/loadre.service";
import { CommonModule } from "@angular/common";
import { LoaderComponent } from "./shared/loader/loader.component";
import { Router, NavigationEnd } from "@angular/router";
import { ViewportScroller } from "@angular/common";
@Component({
  selector: "app-root",
  standalone: true,
  imports: [RouterOutlet, CommonModule, LoaderComponent],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent {
  title = "jewel-kit-pro";
  isLoading$: Observable<boolean>;
  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private loaderService: LoaderService
  ) {
    this.isLoading$ = this.loaderService.loading$;
  }
  // constructor(private loaderService: LoaderService) {
  //   this.isLoading$ = this.loaderService.loading$;
  //   // console.log(this.loaderService.loading$);
  // }
  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
}

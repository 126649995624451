import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject, BehaviorSubject, map } from "rxjs";
import { Login, User, UserData } from "../models/user.model";
import { environment } from "../../environments/environments";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  API_URL = environment.apiUrl;
  private tokenKey = "authToken";

  // BehaviorSubject to track login status
  private loggedIn = new BehaviorSubject<boolean>(this.hasToken());

  // Observable to subscribe to for login status
  loginStatus = this.loggedIn.asObservable();

  constructor(private http: HttpClient) {}

  registerUser(data: User): Observable<User> {
    return this.http.post<User>(this.API_URL + "user/create", data);
  }

  login(data: Login): Observable<UserData> {
    return this.http.post<UserData>(this.API_URL + "user/login", data).pipe(
      map((response: UserData) => {
        this.setToken(response.token);
        this.loggedIn.next(true);
        return response;
      })
    );
  }

  logout(): void {
    this.removeToken();
  }

  setToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
    this.loggedIn.next(true);
  }

  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  removeToken(): void {
    localStorage.clear();
    localStorage.removeItem(this.tokenKey);
    this.loggedIn.next(false);
  }

  hasToken(): boolean {
    return !!localStorage.getItem(this.tokenKey);
  }
}

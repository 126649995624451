import { Component } from "@angular/core";
import { HeaderComponent } from "../../shared/header/header.component";
import { Router, RouterModule } from "@angular/router";
import { FooterComponent } from "../../shared/footer/footer.component";
import { CommonModule } from "@angular/common";
import { UserService } from "../../services/user.service";
import { SharedService } from "../../services/shared.service";
import { LoaderComponent } from "../../shared/loader/loader.component";

@Component({
  selector: "app-base-layout",
  standalone: true,
  imports: [
    HeaderComponent,
    RouterModule,
    FooterComponent,
    CommonModule,
    LoaderComponent,
  ],
  templateUrl: "./base-layout.component.html",
  styleUrl: "./base-layout.component.scss",
})
export class BaseLayoutComponent {
  url: string = "";
  data: any;
  loading: boolean = false;
  constructor(
    private router: Router,
    private _service: UserService,
    private sharedDataService: SharedService
  ) {}

  ngOnInit() {
    this.url = this.router.url;
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      this.getUser();
    }
  }

  getUser() {
    this.loading = true;
    this._service.getUser().subscribe(
      (res) => {
        // console.log(res);
        this.data = res.ResponseData;
        // console.log(this.data);
        this.sharedDataService.setUserData(this.data);
        this.loading = false;
      },
      (error) => {
        this.loading = false;
      }
    );
  }
}

<section class="pricing-tables content-area">
  <div class="container">
    <!-- Main title -->
    <div class="main-title text-center">
      <h1>Subscriptions</h1>

      @if(model.subscription_status === '0' ){
      <p>Currently do not have any active plans.</p>

      }@else {
      <p>Currently have the following active plans.</p>
      }
    </div>
    <div class="row">
      @for(item of plans; track item?._id){

      <div [ngClass]="plans.length > 1 ? 'col-sm-12 col-lg-4 col-md-4' : ''">
        <div class="pricing featured">
          <!-- <div class="listing-badges">
            <span class="featured">Featured</span>
          </div> -->
          <div class="price-header price-header-2">
            <div class="title">{{ item.planName }}</div>
            <div class="price">
              {{ item.planPrice | currency : item.planCurrency.toUpperCase() }}
            </div>
          </div>
          <div class="content">
            <ul>
              @for(features of item.planFeatures; track features){
              <li>{{ features }}</li>

              }
            </ul>
            <div class="button">
              <!-- <a
                [ngClass]="
                  model.subscription_status === '1'
                    ? 'btn-outline pricing-btn button-theme disable-btn'
                    : 'btn-outline pricing-btn button-theme'
                "
                style="cursor: pointer"
                (click)="checkout(item)"
                >Get Started</a
              > -->

              <button
                [ngClass]="
                  model.subscription_status === '1'
                    ? 'btn btn-primary disable-btn'
                    : 'btn btn-primary'
                "
                style="cursor: pointer"
                (click)="checkout(item)"
                [disabled]="isLoading"
              >
                <!-- Sign Up -->

                <!-- Sign In -->
                @if(!isLoading){ Get Started }@else {
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Loading...</span>

                }
              </button>
            </div>
          </div>
        </div>
      </div>

      }
    </div>
  </div>
</section>

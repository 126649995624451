import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class TokenInterceptorService {
  constructor(private authService: AuthService) {}
  intercept<T>(
    req: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    const authToken = this.authService.getToken();

    if (authToken) {
      const cloned = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + authToken),
      });
      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }
}

import { Component, ElementRef, ViewChild } from "@angular/core";
import { ModalPopupComponent } from "../../shared/modal-popup/modal-popup.component";
import { BannerComponent } from "../../shared/banner/banner.component";
import { Router, RouterModule } from "@angular/router";
import { PricingComponent } from "../../components/pricing/pricing.component";

@Component({
  selector: "app-landing-page",
  standalone: true,
  imports: [
    ModalPopupComponent,
    BannerComponent,
    RouterModule,
    PricingComponent,
  ],
  templateUrl: "./landing-page.component.html",
  styleUrl: "./landing-page.component.scss",
})
export class LandingPageComponent {
  token = localStorage.getItem("authToken");
  @ViewChild("videoPlayer", { static: false }) videoPlayer: ElementRef | any;
  constructor(private router: Router) {}

  ngOnInit() {
    // console.log(
    //   "🚀 ~ LandingPageComponent ~ ngOnInit ~ this.token:",
    //   this.token
    // );
    // if (this.token) {
    //   this.router.navigate(["/design"]);
    // }
  }

  ngAfterViewInit(): void {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    video.muted = true;
    video.play().catch((error) => {
      console.error("Error attempting to play video:", error);
    });
  }
}

import { Component } from "@angular/core";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { StripePaymentService } from "../../services/stripe-payment.service";

@Component({
  selector: "app-payment-success",
  standalone: true,
  imports: [RouterModule],
  templateUrl: "./payment-success.component.html",
  styleUrl: "./payment-success.component.scss",
})
export class PaymentSuccessComponent {
  planId = localStorage.getItem("planId") || "";
  userId: string = "";
  sessionId: string = "";
  plan: any;
  transactionId: string = "";
  constructor(
    private _ActivatedRoute: ActivatedRoute,
    private _service: StripePaymentService,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    if (!localStorage.getItem("planId") && !localStorage.getItem("userId")) {
      this.router.navigate(["/"]);
      return;
    }

    this._ActivatedRoute.queryParams.subscribe(async (params: any) => {
      this.sessionId = params["session_id"].split("?")[0];
      const input = params["session_id"].split("?")[2];
      const parts = input.split("=");
      this.userId = parts[1];
    });

    const data = {
      sessionId: this.sessionId,
      planId: this.planId,
      userId: this.userId,
    };
    // console.log(data);
    // return;
    if (this.sessionId) {
      this._service.confirmPayment(data).subscribe((res: any) => {
        if (res.succeeded) {
          localStorage.removeItem("planId");
          localStorage.removeItem("userId");
          this.authService.setToken(res.ResponseData.token);
        }
      });
    }
  }

  navigate() {
    // this.router.navigate(["/design"]);
    window.location.href = "/design";
  }
}

<!-- <div
  class="modal fade bd-example-modal-lg"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myLargeModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <section class="about_section layout_padding-bottom mt-4">
        <div class="container-fluid">
          <div class="row m-2">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="container">
                    <div class="row d-flex align-items-center">
                      <div class="col-md-11">
                        <p class="title">Upgrade your Plan</p>
                      </div>
                      <div class="col-md-1 text-end">
                        <img src="../../../assets/images/close.svg" alt="" />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-md-4 section">
                        <div>
                          <h3 class="heading">Free</h3>
                          <span>USD $0/month</span>
                          <div class="cstm_btn">your current plan</div>
                        </div>
                        <ul class="mt-4">
                          <li>
                            <p>
                              Assistance with writing, problem solving and more
                            </p>
                          </li>
                          <li>
                            <p>Access to GPT-3.5</p>
                          </li>
                          <li>
                            <p>Limited access to GPT-40</p>
                          </li>
                          <li>
                            <p>
                              Limited access to advanced data analysis, file
                              uploads, vision, web browsing, and custom GPTs
                            </p>
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-4 section">
                        <div>
                          <div class="heading">
                            Plus
                            <img
                              src="../../../assets/images/stars.png"
                              alt="star img"
                            />
                          </div>
                          <span>USD $20/month</span>
                          <div class="cstm_btn">Upgrade to plan</div>
                        </div>
                        <ul class="mt-4">
                          <li>
                            <p>Early access to new features</p>
                          </li>
                          <li>
                            <p>Access to GPT-4, GPT-40, GPT- 3.5</p>
                          </li>
                          <li>
                            <p>Up to 5x more messages for GPT-40</p>
                          </li>
                          <li>
                            <p>
                              Access to advanced data analysis, file uploads,
                              vision, and web browsing
                            </p>
                          </li>
                          <li>
                            <p>DALL-E image generation</p>
                          </li>
                          <li>
                            <p>Create and use custom GPTs</p>
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-4 section">
                        <div>
                          <div class="heading">
                            Team
                            <img
                              src="../../../assets/images/users.png"
                              alt="star img"
                            />
                          </div>
                          <span>USD $26/per person/month*</span>
                          <div class="cstm_btn">Upgrade to team</div>
                        </div>
                        <ul class="mt-4">
                          <li>
                            <p>
                              Higher limits for GPT-4, GPT-40, and tools like
                              DALL-E image generation, advanced data analysis,
                              web browsing, and more
                            </p>
                          </li>
                          <li>
                            <p>Create and share GPTs with your workspace</p>
                          </li>
                          <li>
                            <p>Admin console for workspace management</p>
                          </li>
                          <li>
                            <p>
                              Team data excluded from training by default. Learn
                              more
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div> -->

<div class="cstm-modal">
  <div class="container-fuild">
    <div class="modal-overlay" *ngIf="isVisible" (click)="close()">
      <div class="modal-content" (click)="$event.stopPropagation()">
        <!-- <div class="modal-header">
        <h2 class="title">{{ title }}</h2>
        <div (click)="close()" class="close-button"><img src="../../../assets/images/close.svg" alt="" /></div>
      </div> -->
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row m-2">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="container">
                      <div class="row d-flex align-items-center">
                        <div class="col-md-11">
                          <p class="title">Upgrade your Plan</p>
                        </div>
                        <div class="col-md-1 text-end" (click)="close()">
                          <img src="../../../assets/images/close.svg" alt="" />
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-md-4 section">
                          <div>
                            <h3 class="heading">Free</h3>
                            <span>USD $0/month</span>
                            <div class="cstm_btn">your current plan</div>
                          </div>
                          <ul class="custom-bullets mt-4">
                            <li>
                              Assistance with writing, problem solving and more
                            </li>
                            <li>Access to GPT-3.5</li>
                            <li>Limited access to GPT-40</li>
                            <li>
                              Limited access to advanced data analysis, file
                              uploads, vision, web browsing, and custom GPTs
                            </li>
                            <div class="bottom_text">
                              <p>Have an existing plan? See billing help</p>
                            </div>
                          </ul>
                        </div>

                        <div class="col-md-4 section">
                          <div>
                            <div class="heading">
                              Plus
                              <img
                                src="../../../assets/images/stars.png"
                                alt="star img"
                              />
                            </div>
                            <span>USD $20/month</span>
                            <div class="cstm_btn">Upgrade to plan</div>
                          </div>
                          <ul class="custom-bullets mt-4">
                            <li>Early access to new features</li>
                            <li>Access to GPT-4, GPT-40, GPT- 3.5</li>
                            <li>Up to 5x more messages for GPT-40</li>
                            <li>
                              Access to advanced data analysis, file uploads,
                              vision, and web browsing
                            </li>
                            <li>DALL-E image generation</li>
                            <li>Create and use custom GPTs</li>
                            <div class="bottom_text">
                              <a>Limits apply</a>
                            </div>
                          </ul>
                        </div>

                        <div class="col-md-4 section">
                          <div>
                            <div class="heading">
                              Team
                              <img
                                src="../../../assets/images/users.png"
                                alt="star img"
                              />
                            </div>
                            <span>USD $26/per person/month*</span>
                            <div class="cstm_btn">Upgrade to team</div>
                          </div>

                          <ul class="custom-bullets mt-4">
                            <div
                              class="mb-4"
                              style="
                                font-size: 14px;
                                line-height: 15.4px;
                                font-weight: 400;
                                color: #535353;
                              "
                            >
                              Everything in Plus, and:
                            </div>
                            <li>
                              Higher limits for GPT-4, GPT-40, and tools like
                              DALL-E image generation, advanced data analysis,
                              web browsing, and more
                            </li>
                            <li>Create and share GPTs with your workspace</li>
                            <li>Admin console for workspace management</li>
                            <li>
                              Team data excluded from training by default. Learn
                              more
                            </li>

                            <div>
                              <p>*Price billed annually, minimum 2 users</p>
                            </div>
                          </ul>
                        </div>
                      </div>
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <p>Need more capabilities? Sea ChatGPT Enterprise</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="modal-footer">
        <button (click)="close()" class="btn btn-secondary">Close</button>
      </div> -->
      </div>
    </div>
  </div>
</div>

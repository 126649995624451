import {
  Component,
  HostListener,
  OnInit,
  OnDestroy,
  Input,
} from "@angular/core";
import { Router, NavigationEnd, RouterModule } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { CommonModule } from "@angular/common";
import { filter, Subscription } from "rxjs";
import { SharedService } from "../../services/shared.service";

@Component({
  selector: "app-header",
  standalone: true,
  imports: [RouterModule, CommonModule],
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isLoggedIn: boolean = false;
  isSticky: boolean = false;
  token: string = "";
  url: string = "";
  data: any;
  private loginStatusSub: Subscription | any;

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const offset =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    this.isSticky = offset > 100;
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private sharedDataService: SharedService
  ) {}

  ngOnInit() {
    this.token = this.authService.getToken() || "";
    this.isLoggedIn = this.authService.hasToken();
    if (this.token) {
      this.loginStatusSub = this.authService.loginStatus.subscribe(
        (status: boolean) => {
          this.isLoggedIn = status;
          // console.log(this.isLoggedIn);
        }
      );
    }

    this.sharedDataService.userData$.subscribe((data) => {
      this.data = data;
    });
    // this.router.events
    //   .pipe(filter((event: any) => event instanceof NavigationEnd))
    //   .subscribe((event: NavigationEnd) => {
    //     this.url = event.url;
    //     this.isLoggedIn = this.checkLoginStatus();
    //     console.log(this.isLoggedIn);
    //   });
  }

  ngOnDestroy() {
    if (this.loginStatusSub) {
      this.loginStatusSub.unsubscribe();
    }
  }

  checkLoginStatus(): boolean {
    return this.token !== null;
  }

  closeNavbar(navbarNav: HTMLInputElement) {
    navbarNav.checked = false;
  }

  logout() {
    this.authService.logout();
    // this.router.navigate(["/"]);
    window.location.href = "/";
    sessionStorage.clear();
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from '../models/plan.model';
import { environment } from '../../environments/environments';

@Injectable({
  providedIn: 'root',
})
export class PalnsService {
  API_URL = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getAllPlans(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.API_URL + 'plans/get-plans');
  }

 
}

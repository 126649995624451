import { Component } from "@angular/core";
import { ChatService } from "../../services/chat.service";
import { ChatHistory } from "../../models/chatHistory.model";
import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-history",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./history.component.html",
  styleUrl: "./history.component.scss",
})
export class HistoryComponent {
  chatHistory: any;
  isImageLoad: boolean = true;
  constructor(private _chatHistory: ChatService, private http: HttpClient) {}

  ngOnInit() {
    this.fetchHistory();
  }

  fetchHistory() {
    this._chatHistory.getChatHistory().subscribe((res) => {
      // console.log(res);

      this.chatHistory = res.ResponseData;
    });
  }

  download(url: string) {
    // console.log(url);
    // const link = document.createElement("a");
    // link.href = url;
    // link.download = this.getFileName(url);
    // console.log("🚀 ~ HomeComponent ~ download ~ link:", link);
    // link.click();.
    this.http.get(url, { responseType: "blob" }).subscribe((blob) => {
      const downloadLink = document.createElement("a");
      const objectUrl = URL.createObjectURL(blob);
      downloadLink.href = objectUrl;
      downloadLink.download = url.split("/").pop()!;
      downloadLink.click();
      URL.revokeObjectURL(objectUrl);
    });
  }

  onLoad() {
    this.isImageLoad = false;
  }

  
}

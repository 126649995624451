import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class ImageDownloadService {
  constructor(private http: HttpClient) {}

  downloadImage(url: string): Observable<Blob> {
    // const proxiedUrl = url.split("https://oaidalleapiprodscus.blob.core.windows.net")[1];
    // console.log(proxiedUrl);
    return this.http.get(url, { responseType: "blob" });
  }

  fetchImageAsBlob(imageUrl: string): Observable<Blob> {
    return this.http.get(imageUrl, { responseType: "blob" });
  }
}

import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { provideRouter } from "@angular/router";

import { routes } from "./app.routes";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { provideToastr } from "ngx-toastr";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideNgxStripe } from "ngx-stripe";
import { LoaderService } from "./services/loadre.service";
import { HttpInterceptorService } from "./interceptors/http-interceptor.service";
import { TokenInterceptorService } from "./interceptors/token-interceptor.service";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    importProvidersFrom(HttpClientModule),
    provideAnimations(),
    provideToastr({
      timeOut: 10000,
      positionClass: "toast-top-right",
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      progressBar: true,
      progressAnimation: "increasing",
      // countDuplicates: true,
      // includeTitleDuplicates: true,
    }), // Toastr providers
    provideNgxStripe(
      "pk_test_51PNr0r02646MFSZc8IMGf4usNW9qmtijYCqd6LwUkoR086CnBNy9daUfcZyfQwu3rBmd9QzLrSf0WR7bKup5yDwn005H2ElCrz"
    ),
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
  ],
};

<div class="container">
  <div class="card">
    <div class="card-header">
      <h2>Payment Successful</h2>
    </div>
    <div class="card-body">
      <p>Your payment has been successfully processed.</p>
      <p>Thank you for your purchase!</p>
      <!-- <div class="details">
        <p><strong>Plan Name:</strong> {{ plan?.planName }}</p>
        <p><strong>Amount Paid:</strong> {{ plan?.planPrice }} {{ plan?.planCurrency }}</p>
        <p><strong>Transaction ID:</strong> {{ transactionId }}</p>
      </div> -->
      <a (click)="navigate()" class="btn">Go to Home</a>
    </div>
  </div>
</div>

import { Routes } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
import { OurPricingComponent } from "./components/our-pricing/our-pricing.component";
import { PaymentSuccessComponent } from "./components/payment-success/payment-success.component";
import { PricingComponent } from "./components/pricing/pricing.component";
import { BaseLayoutComponent } from "./layouts/base-layout/base-layout.component";
import { LandingPageComponent } from "./pages/landing-page/landing-page.component";
import { LoginComponent } from "./pages/login/login.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { authGuard } from "./guard/auth.guard";
import { HistoryComponent } from "./components/history/history.component";
import { PaymentCancelComponent } from "./components/payment-cancel/payment-cancel.component";

export const routes: Routes = [
  {
    path: "",
    component: BaseLayoutComponent,
    children: [
      { path: "pricing", component: LandingPageComponent },
      {
        path: "design",
        component: HomeComponent,
        canActivate: [authGuard],
      },

      { path: "our-pricing", component: OurPricingComponent },
      { path: "success", component: PaymentSuccessComponent },
      { path: "history", component: HistoryComponent },

      { path: "", redirectTo: "/pricing", pathMatch: "full" },
    ],
  },
  { path: "cancel", component: PaymentCancelComponent },
  { path: "login", component: LoginComponent },
  { path: "**", component: PageNotFoundComponent },
];

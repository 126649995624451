import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { NgxSpinnerModule } from "ngx-spinner";

@Component({
  selector: "app-image-loader",
  standalone: true,
  imports: [NgxSpinnerModule, CommonModule],
  templateUrl: "./image-loader.component.html",
  styleUrl: "./image-loader.component.scss",
})
export class ImageLoaderComponent {
  @Input() src: string = "";
  @Input() alt: string = "";
  isLoading: boolean = true;

  onLoad() {
    console.log(this.isLoading)
    this.isLoading = false;
  }
}

<app-header></app-header>

<section class="login_section">
  <div [ngClass]="isShow === false ? 'container ' : 'container active'">
    <div class="user signinBx">
      <div class="formBx left">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit('signIn')">
          <h2>Welcome Back!</h2>

          <div style="position: relative">
            <div class="input-container">
              <input
                type="email"
                placeholder="Email Address"
                formControlName="email"
              />
              <div class="error-message">
                <div
                  *ngIf="
                    loginSubmitted ||
                    (loginForm.get('email')?.touched &&
                      loginForm.get('email')?.invalid)
                  "
                  class="errors"
                >
                  <small *ngIf="loginForm.get('email')?.errors?.['required']"
                    >Email is required.</small
                  >
                  <small
                    *ngIf="loginForm.get('email')?.errors?.['customEmail'] && !loginForm.get('email')?.errors?.['required']"
                    >Please enter a valid email address.</small
                  >
                </div>
              </div>
            </div>

            <div class="input-container">
              <input
                [type]="!hide ? 'password' : 'text'"
                placeholder="Password"
                formControlName="password"
              />
              <mat-icon (click)="clickEvent($event)" style="cursor: pointer">{{
                hide ? "visibility_off" : "visibility"
              }}</mat-icon>
              <div class="error-message">
                <div
                  *ngIf="
                    loginSubmitted ||
                    (loginForm.get('password')?.touched &&
                      loginForm.get('password')?.invalid)
                  "
                  class="errors"
                >
                  <small *ngIf="loginForm.get('password')?.errors?.['required']"
                    >Password is required.</small
                  >
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center" style="margin-top: 2rem">
              <button
                class="btn btn-primary"
                id="signIn"
                type="submit"
                [disabled]="isLoading"
              >
                @if(!isLoading){ Sign In }@else {
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Loading...</span>
                }
              </button>
            </div>
            <p class="signup text-center singup_link" style="color: #fff">
              Don't have an account?
              <a
                id="signUp"
                style="cursor: pointer; color: #fff"
                (click)="toggleForm($event)"
                >Sign up.</a
              >
            </p>
          </div>
        </form>
      </div>
      <div class="formBx res_scr">
        <form action="" onsubmit="return false;">
          <h2>Get Started Now!</h2>
          <div class="text-center info">
            <p>Subscription Plan</p>
            <h3>35€/month</h3>
            <p>for unlimited designs and 3D renders.</p>
          </div>
          <div class="d-flex justify-content-center" style="margin-top: 2.7rem">
            <button
              class="btn btn-primary"
              id="signUp"
              (click)="toggleForm($event)"
            >
              Sign Up
            </button>
          </div>
          <!-- <input type="text" name="" placeholder="Username" />
          <input type="password" name="" placeholder="Password" />
          <input type="submit" name="" value="Login" />
          <p class="signup">
            Don't have an account ?
            <a  id="signUp" (click)="toggleForm($event);">Sign Up.</a>
          </p> -->
        </form>
      </div>
    </div>

    <div class="user signupBx">
      <div class="formBx input-form">
        <form [formGroup]="signUpForm" (ngSubmit)="onSubmit('signUp')">
          <h2>Create an account</h2>
          <div class="row">
            <!-- <div class="col-12 d-flex justify-content-center">
              <input type="text" name="" placeholder="User Name" formControlName="username" />
            </div> -->
            <div class="col-12 d-flex justify-content-center">
              <input
                type="text"
                name=""
                placeholder="Full Name"
                formControlName="name"
              />
            </div>
            <div
              *ngIf="
                submitted ||
                (signUpForm.get('name')?.touched &&
                  signUpForm.get('name')?.invalid)
              "
              class="errors"
            >
              <small *ngIf="signUpForm.get('name')?.errors?.['required']"
                >Name is required.</small
              >
            </div>
            <div class="col-12 d-flex justify-content-center">
              <input type="text" placeholder="Email" formControlName="email" />
            </div>
            <div
              *ngIf="
                submitted ||
                (signUpForm.get('email')?.touched &&
                  signUpForm.get('email')?.invalid)
              "
              class="errors"
            >
              <small *ngIf="signUpForm.get('email')?.errors?.['required']">
                Email is required.
              </small>
              <small
                *ngIf="signUpForm.get('email')?.errors?.['customEmail'] && !signUpForm.get('email')?.errors?.['required']"
              >
                Please enter a valid email address.
              </small>
            </div>
            <div class="col-12 d-flex justify-content-center">
              <input
                [type]="!hide ? 'password' : 'text'"
                name=""
                placeholder="Password"
                formControlName="password"
              />
              <mat-icon
                (click)="clickEvent($event)"
                class="sign"
                style="cursor: pointer"
                >{{ hide ? "visibility_off" : "visibility" }}</mat-icon
              >
            </div>
            <div
              *ngIf="
                submitted ||
                (signUpForm.get('password')?.touched &&
                  signUpForm.get('password')?.invalid)
              "
              class="errors"
            >
              <small *ngIf="signUpForm.get('password')?.errors?.['required']">
                Password is required.
              </small>
              <small *ngIf="signUpForm.get('password')?.errors?.['minlength']">
                Password must be at least 8 characters long.
              </small>
              <small *ngIf="signUpForm.get('password')?.errors?.['pattern']">
                Password must include uppercase, lowercase, number, and special
                character.
              </small>
            </div>
          </div>
          <!-- <input type="email" name="" placeholder="Email Address" />
          <input type="password" name="" placeholder="Create Password" />
          <input type="password" name="" placeholder="Confirm Password" /> -->

          <div class="d-flex justify-content-center mt-4">
            <button class="btn btn-primary" type="submit">
              <!-- Sign Up -->

              <!-- Sign In -->
              @if(!isLoading){ Sign Up }@else {
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Loading...</span>

              }
            </button>
          </div>
          <!-- <input type="submit" name="" value="Sign Up" /> -->
          <p class="signup text-center">
            Already have an account ?
            <a
              id="signIn"
              style="cursor: pointer; color: var(--bg)"
              (click)="toggleForm($event)"
              >Sign in.</a
            >
          </p>
        </form>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>

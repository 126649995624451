<div class="container bg">
  <footer class="">
    <div class="row">
      <div class="col-md-3 col-12 animate__animated">
        <span>Connect</span>
        <p>{{ email }}</p>
      </div>
      <div class="col-md-3 col-12 animate__animated">
        <span>Follow</span>
        <ul class="social-icons">
          <li>
            <a
              href="https://www.facebook.com/people/Jewel-Kit-Pro/100091975845477/"
              target="_blank"
            >
              <!-- <img src="../../../assets/images/facebook.svg" alt="Facebook" /> -->
              <i class="fab fa-facebook"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/jewelkitpro/?igshid=MjEwN2IyYWYwYw%3D%3D"
              target="_blank"
            >
              <!-- <img src="../../../assets/images/instagram.svg" alt="Instagram" /> -->
              <i class="fab fa-instagram"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.tiktok.com/@jewelkitpro?_t=8cpVIUIWfXO&_r=1"
              target="_blank"
            >
              <!-- <img src="../../../assets/images/tiktok.svg" alt="TikTok" /> -->
              <i class="fab fa-tiktok"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/jewelkitpro/"
              target="_blank"
            >
              <!-- <img src="../../../assets/images/linkedin.svg" alt="LinkedIn" /> -->
              <i class="fab fa-linkedin-in"></i>
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/@JewelKitPro" target="_blank">
              <!-- <img src="../../../assets/images/youtube.svg" alt="YouTube" /> -->
              <i class="fab fa-youtube"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</div>

<section class="pricing-tables content-area">
  <div class="container">
    <!-- Main title -->
    <div class="main-title text-center">
      <h1>Subscriptions</h1>
      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->
    </div>
    <div class="row">
      @for(item of plans; track item?._id){

      <div [ngClass]="plans.length > 1 ? 'col-sm-12 col-lg-4 col-md-4' : ''">
        <div class="pricing featured">
          <!-- <div class="listing-badges">
            <span class="featured">Featured</span>
          </div> -->
          <div class="price-header price-header-2">
            <div class="title">{{ item.planName }}</div>
            <div class="price">
              {{ item.planPrice | currency : item.planCurrency.toUpperCase() }}
            </div>
          </div>
          <div class="content">
            <ul>
              @for(features of item.planFeatures; track features){
              <li>{{ features }}</li>

              }
            </ul>
            <div class="button">
              <a
                [ngClass]="
                  model.subscription_status === '1'
                    ? 'btn-outline pricing-btn button-theme disable-btn'
                    : 'btn-outline pricing-btn button-theme'
                "
                >Get Started</a
              >
            </div>
          </div>
        </div>
      </div>

      }
    </div>
  </div>
</section>

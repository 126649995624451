import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  private loggedIn = new BehaviorSubject<boolean>(this.hasToken());
  private userDataSource = new BehaviorSubject<any>(null);
  userData$ = this.userDataSource.asObservable();
  loginStatus = this.loggedIn.asObservable();
  
  constructor() {}
  private hasToken(): boolean {
    return !!localStorage.getItem("token");
  }

  setUserData(data: any) {
    this.userDataSource.next(data);
  }

  getToken(): string | null {
    return localStorage.getItem("token");
  }

  login(token: string): void {
    localStorage.setItem("token", token);
    this.loggedIn.next(true);
  }

  logout(): void {
    localStorage.removeItem("token");
    this.loggedIn.next(false);
  }
}

<section class="video-section">
  <div class="container d-flex justify-content-center">
    <div class="card gif_card d-flex justify-content-center align-items-center">
      <video
        #videoPlayer
        autoplay
        loop
        muted
        playsinline
        style="width: 100%; border-radius: 20px"
      >
        <source src="../../../assets/Video.MP4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</section>

<section class="info-section">
  <div class="container info_container">
    <div class="row">
      <div class="col-md-4 col-12">
        <h1 class="title">Design without limits!</h1>
      </div>
      <div class="col-md-8 col-12">
        <div class="card info_card">
          <div class="card-body">
            <h1 class="text-center mb-4">Transform Ideas Into Reality</h1>
            <div class="mid_section text-center">
              <p>Subscription Plan</p>
              <h3>35€/month</h3>
              <p>for unlimited designs and 3D renders.</p>
            </div>
            <div class="text-center">
              <button [routerLink]="token ? '/design' : '/login'">
                Get STARTED Now
                <!-- {{ token ? "Create Your Design" : "Get STARTED Now" }} -->
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

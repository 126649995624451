import { CommonModule } from "@angular/common";
import { Component, HostListener } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { UserData } from "../../models/user.model";
import { AuthService } from "../../services/auth.service";
import { HeaderComponent } from "../../shared/header/header.component";
import { FooterComponent } from "../../shared/footer/footer.component";
import { strongPasswordValidator } from "../../utils/strong-password-validation";
import { customEmailValidator } from "../../utils/customEmailValidator";
import { UserService } from "../../services/user.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-login",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    HeaderComponent,
    FooterComponent,
  ],
  templateUrl: "./login.component.html",
  styleUrl: "./login.component.scss",
})
export class LoginComponent {
  loginForm!: FormGroup;
  signUpForm!: FormGroup;
  isShow: boolean = false;
  successMessage: string = "";
  // errorMessage: string = "";
  loginSubmitted: boolean = false;
  submitted: boolean = false;
  hide: boolean = false;
  isLoading: boolean = false;
  isSmallScreen = false;

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.checkScreenSize();
  }

  constructor(
    private _fb: FormBuilder,
    private _service: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    localStorage.clear();
    this.loginForm = this._fb.group({
      email: ["", [Validators.required, customEmailValidator()]],
      password: ["", [Validators.required]],
    });
    this.signUpForm = this._fb.group({
      // username: ["", Validators.required],
      name: ["", Validators.required],
      email: ["", [Validators.required, customEmailValidator()]],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
          ),
        ],
      ],
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  get f1() {
    return this.signUpForm.controls;
  }
  checkScreenSize() {
    this.isSmallScreen = window.innerWidth < 768;
  }

  toggleForm(e: MouseEvent) {
    const target = e.target as HTMLElement;
    this.loginSubmitted = false;
    this.submitted = false;
    this.loginForm.reset();
    this.signUpForm.reset();
    this.hide = false;
    const result =
      target?.id === "signUp" ? (this.isShow = true) : (this.isShow = false);
  }

  clickEvent(event: any) {
    this.hide = !this.hide;
  }

  onSubmit(e: string) {
    // return

    if (e === "signIn") {
      // const { email, password } = this.loginForm.value;
      this.loginSubmitted = true;

      if (this.loginForm.invalid) {
        return;
      }
      const data = {
        email: this.loginForm.get("email")?.value,
        password: this.loginForm.get("password")?.value,
      };
      this.isLoading = true;

      this._service.login(data).subscribe(
        (res: UserData) => {
          // console.log(res);
          if (res.succeeded) {
            this.successMessage = res.ResponseMessage;
            this._service.setToken(res.ResponseData);

            this.checkSubscription();
            // return;
          }
        },
        (error) => {
          // this.errorMessage = error;
          // console.log(this.errorMessage);
          this.toastr.clear();
          this.toastr.error(error.error.ResponseMessage, "Error");
          this.isLoading = false;
        }
      );
    } else if (e === "signUp") {
      this.submitted = true;
      if (this.signUpForm.invalid) {
        return;
      }
      let data = {
        name: this.signUpForm.value.name,
        email: this.signUpForm.value.email,
        password: this.signUpForm.value.password,
      };
      this.isLoading = true;
      this._service.registerUser(data).subscribe(
        (res: any) => {
          console.log(res);
          this.signUpForm.reset();
          // this.toastr.success("User registered successfully", "Success");
          this.submitted = false;
          this.isLoading = false;
          localStorage.setItem("userId", res.ResponseData._id);
          this.router.navigate(["/our-pricing"]); // navigate our-pricing route
        },
        (error) => {
          this.toastr.clear();
          this.toastr.error(error.error.ResponseMessage, "Error");
          this.isLoading = false;
        }
      );
    }
  }

  checkSubscription = async () => {
    await this.userService.getUser().subscribe((res) => {
      if (res.ResponseData.status == "0") {
        this.toastr.clear();
        Swal.fire({
          icon: "error",
          title: "Account Deactivated",
          text: "Your profile has been deactivated by the admin. Please contact support for further assistance.",
        });
        localStorage.clear();
        this.isLoading = false;
        return;
      }
      if (res.ResponseData.subscription_status === "0") {
        localStorage.setItem("userId", res.ResponseData._id);
        this.router.navigate(["/our-pricing"]);
        this.toastr.success(this.successMessage, "Success");
        this.loginSubmitted = false;
        this.isLoading = false;
      } else {
        localStorage.setItem("userId", res.ResponseData._id);

        this.router.navigate(["/design"]);
        this.toastr.success(this.successMessage, "Success");

        this.loginSubmitted = false;
        this.isLoading = false;
      }
    });
  };
}

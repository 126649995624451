<nav [ngClass]="isSticky ? 'header' : ''">
  <input type="checkbox" id="nav-toggle" #navToggle />
  <a class="logo" href="https://jewelkitgenius.com/">
    <img
      src="../../../assets/images/logo.png"
      alt=""
      class="d-none d-md-block"
    />
    <img
      src="../../../assets/images/smlogo.png"
      alt=""
      class="d-block d-md-none"
    />
  </a>
  <ul class="links">
    <li>
      <span class="d-flex align-items-center cstm-span">
        <a
          href="https://jewelkitgenius.com/about"
          (click)="closeNavbar(navToggle)"
          >About</a
        >
        <i class="fas fa-caret-down dropdown-icon"></i>
      </span>
      <ul class="dropdown">
        <li class="dropdown-link">
          <a
            href="https://jewelkitgenius.com/features"
            (click)="closeNavbar(navToggle)"
            >Features</a
          >
        </li>
        <li class="dropdown-link">
          <a
            href="https://jewelkitgenius.com/gallery"
            (click)="closeNavbar(navToggle)"
            >Gallery</a
          >
        </li>
      </ul>
    </li>
    <li>
      <a
        routerLink="/pricing"
        routerLinkActive="active"
        (click)="closeNavbar(navToggle)"
        >Pricing</a
      >
    </li>
    <li>
      <a
        href="https://jewelkitgenius.com/#contact"
        (click)="closeNavbar(navToggle)"
        >Contact</a
      >
    </li>
    @if(isLoggedIn){
    <li>
      <span class="d-flex align-items-center cstm-span">
        <a>Account </a><i class="fas fa-caret-down dropdown-icon"></i>
      </span>
      <ul class="dropdown">
        <li class="dropdown-link" *ngIf="data?.subscription_status === '1'">
          <a
            routerLink="/design"
            routerLinkActive="active"
            (click)="closeNavbar(navToggle)"
            >Design</a
          >
        </li>
        <li class="dropdown-link" *ngIf="data?.subscription_status === '1'">
          <a
            routerLink="/history"
            routerLinkActive="active"
            (click)="closeNavbar(navToggle)"
            >History</a
          >
        </li>
        <li class="dropdown-link">
          <a
            (click)="logout()"
            style="cursor: pointer"
            (click)="closeNavbar(navToggle)"
            >Logout</a
          >
        </li>
      </ul>
    </li>

    }@else {
    <li>
      <a
        routerLink="/login"
        routerLinkActive="active"
        (click)="closeNavbar(navToggle)"
        >Account</a
      >
    </li>
    }
  </ul>
  <label for="nav-toggle" class="icon-burger">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </label>
</nav>

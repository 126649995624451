<section class="about_section layout_padding-bottom">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3">
        <div class="card res-card">
          <div class="card-header d-md-none p-2">
            <form [formGroup]="chattingForm" (ngSubmit)="onSubmit()">
              <div class="form-container">
                <div class="search-container">
                  <input
                    type="text"
                    class="search-input"
                    placeholder="Ask JewelKitGenius"
                    formControlName="prompt"
                  />
                  <button
                    class="search-button"
                    type="submit"
                    [disabled]="isLoading"
                  >
                    @if(!isLoading){

                    <i class="fas fa-arrow-circle-down"></i>
                    }@else {
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Loading...</span>

                    }
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="card-body">
            
            @for(msg of messages.slice().reverse(); track msg){
            <div class="message-container">
              <div class="message-bubble">
                {{ msg }}
              </div>
            </div>

            }
          </div>

          <div class="card-footer d-none d-md-block">
            <form [formGroup]="chattingForm" (ngSubmit)="onSubmit()">
              <div class="form-container">
                <div class="search-container">
                  <input
                    type="text"
                    class="search-input"
                    placeholder="Ask JewelKitGenius"
                    formControlName="prompt"
                  />
                  <button
                    class="search-button"
                    type="submit"
                    [disabled]="isLoading"
                  >
                    @if(!isLoading){

                    <i class="fas fa-arrow-circle-up"></i>
                    }@else {
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="sr-only">Loading...</span>

                    }
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-9 result">
        <div class="card p-0">
          <div class="card-header">
            <h2>your Designs</h2>
          </div>
          <div class="card-body">
            <div class="row">
              @for(item of previews.slice().reverse(); track item){

              <div
                class="col-md-4 col-12 p-4 res-container d-flex justify-content-center"
              >
                <div class="skeleton-loader" *ngIf="isImageLoad"></div>

                <img
                  *ngIf="item?.imageUrl"
                  [src]="item?.imageUrl"
                  class="img-res"
                  (load)="onLoad()"
                  loading="lazy"
                />
                <div class="download_img" *ngIf="!isImageLoad">
                  <span
                    class="download_btn"
                    (click)="download(item?.downloadImagePath)"
                  >
                    <img
                      src="../../../assets/images/download1.png"
                      width="30"
                      height="30"
                    />
                  </span>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- <app-loader [isLoading]="loading"></app-loader> -->

import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router, RouterModule } from "@angular/router";
import { Observable } from "rxjs";
import { UserService } from "../../services/user.service";
import { ModalPopupComponent } from "../../shared/modal-popup/modal-popup.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { ImageLoaderComponent } from "../../shared/image-loader/image-loader.component";
import { ImageDownloadService } from "../../services/image-download.service";
import { HttpClient } from "@angular/common/http";
import { LoaderComponent } from "../../shared/loader/loader.component";
import { SharedService } from "../../services/shared.service";
import { generateSessionId } from "../../utils/session-id.util";
declare var $: any;

@Component({
  selector: "app-home",
  standalone: true,
  imports: [
    RouterModule,
    ModalPopupComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    ImageLoaderComponent,
    LoaderComponent,
  ],
  templateUrl: "./home.component.html",
  styleUrl: "./home.component.scss",
})
export class HomeComponent {
  userId = localStorage.getItem("userId") || "";
  messages: string[] = [];
  chattingForm!: FormGroup;
  selectedFiles?: FileList;
  previews: any[] = [];
  imageInfos?: Observable<any>;
  isLoading: boolean = false;
  isImageLoad: boolean = true;
  loading: boolean = true;
  sessionId: string = "";
  constructor(
    public dialog: MatDialog,
    private _fb: FormBuilder,
    private _userService: UserService,
    private router: Router,
    private imageDownloadService: ImageDownloadService,
    private http: HttpClient,
    private sharedDataService: SharedService
  ) {}

  ngOnInit(): void {
    // this.sessionId = localStorage.getItem("sessionId") || "";
    // if (!this.sessionId) {
    //   this.sessionId = generateSessionId();
    //   localStorage.setItem("sessionId", this.sessionId);
    // }
    this.sessionId = generateSessionId();
    localStorage.setItem("sessionId", this.sessionId);
    this.chattingForm = this._fb.group({
      prompt: [null],
    });
    this.fetchUser();
  }

  // openDialog() {
  //   this.dialog.open(ModalPopupComponent);
  // }

  fetchUser() {
    // this.loading = true;

    this.sharedDataService.userData$.subscribe((data) => {
      // console.log(data)
      // this.loading = false;
      //  this.data = data;
      if (data?.subscription_status === "0") {
        this.loading = false;
        localStorage.setItem("userId", data._id);
        this.router.navigate(["our-pricing"]);
        // console.log(data);
      }
    });
    // this._userService.getUser().subscribe((res) => {
    //   this.loading = false;

    //   if (res.ResponseData.subscription_status === "0") {
    //     this.loading = false;
    //     localStorage.setItem("userId", res.ResponseData._id);
    //     this.router.navigate(["our-pricing"]);
    //   }
    // });
  }

  // onFileSelect(event: any) {
  //   this.selectedFiles = event.target.files;

  //   this.previews = [];
  //   if (this.selectedFiles && this.selectedFiles[0]) {
  //     const numberOfFiles = this.selectedFiles.length;
  //     for (let i = 0; i < numberOfFiles; i++) {
  //       const reader = new FileReader();

  //       reader.onload = (e: any) => {
  //         this.previews.push(e.target.result);
  //       };

  //       reader.readAsDataURL(this.selectedFiles[i]);
  //     }
  //   }
  // }

  // removeImage(index: number): void {
  //   this.previews.splice(index, 1);
  // }

  newDesign() {
    this.messages = [];
    this.previews = [];
  }

  onSubmit() {
    if (this.chattingForm.value.prompt !== null) {
      this.isLoading = true;
      const data = {
        prompt: this.chattingForm.value.prompt,
        sessionId: this.sessionId,
      };
      this._userService.ganerateImage(data).subscribe(
        (res) => {
          // console.log(res);
          this.messages.push(this.chattingForm.value.prompt);
          this.previews.push(res.ResponseData);

          this.chattingForm.reset();
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    }
  }

  download(url: string) {
    // console.log(url);
    // const link = document.createElement("a");
    // link.href = url;
    // link.download = this.getFileName(url);
    // console.log("🚀 ~ HomeComponent ~ download ~ link:", link);
    // link.click();.
    this.http.get(url, { responseType: "blob" }).subscribe((blob) => {
      const downloadLink = document.createElement("a");
      const objectUrl = URL.createObjectURL(blob);
      downloadLink.href = objectUrl;
      downloadLink.download = url.split("/").pop()!;
      downloadLink.click();
      URL.revokeObjectURL(objectUrl);
    });
  }

  getFileName(url: string): string {
    const parts = url.split("/");
    return parts[parts.length - 1];
  }

  openImageInNewTab(url: string) {
    window.open(url, "_blank");
  }

  onLoad() {
    this.isImageLoad = false;
  }
}
